<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="config. condições" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">

                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <b-tabs>
                                    <b-tab class="tab-pane" id="commercial">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-envelope font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Condições comerciais</span>
                                            </div>
                                        </template>

                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <textarea class="form-control" rows="7" v-model="condition.commercial" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>

                                    <b-tab class="tab-pane" id="terms">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-frown font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Termos de uso</span>
                                            </div>
                                        </template>
                                            
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <textarea class="form-control" rows="7" v-model="condition.terms" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>

                                    <b-tab class="tab-pane" id="privacy">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-envelope-open font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Política de privacidade</span>
                                            </div>
                                        </template>

                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Mensagem</label>
                                                            <textarea class="form-control" rows="7" v-model="condition.privacy_policy" placeholder=""></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </b-tab>
                                     <b-tab class="tab-pane" id="privacy">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-envelope-open font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Frase personalização cliente</span>
                                            </div>
                                        </template>

                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Mensagem</label>
                                                            <textarea class="form-control" rows="7" v-model="condition.personalization_phrase" placeholder=""></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </b-tab>
                                </b-tabs>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-info">Salvar</button>
                                    </div>
                                </div>
                            </form>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ConditionService from "@/services/resources/ConditionService";
const service = ConditionService.build();

export default {
  data() {
    return {
      condition: {
          id: 0,
          commercial: null,
          terms: null,
          privacy_policy: null,
          personalization_phrase: ""
      },
      pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            },
            {
                name: 'config. condições',
                to: 'ConfigsCondition'
           }
      ],
      menu:[],
    };
  },
  methods: {
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {

            service
            .update(this.condition)
            .then(resp => {
                 this.$bvToast.toast('Atualizado com sucesso!', {
                    title: 'Condições',
                    autoHideDelay: 5000,
                    type: 'success'
                })
            })
            .catch(err => {
                console.log(err)
            })
          }
      });
    },
    fetch(){
        
        service
        .search()
        .then(resp => {
            if(resp){
                this.condition = resp;
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

  },
  mounted() {
      this.fetch();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>